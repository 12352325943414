<template>
	<div class="container" v-title data-title="证书查询">
    <div class="breadcrumbs-box">
      <div class="breadcrumbs">
        <i class="el-icon-s-home home-icon"></i>
        <div class="item">
          <div>
            <router-link to="/">首页</router-link>
          </div>
        </div>
        <i class="el-icon-arrow-right right-icon"></i>
        <div class="item">
          <div>证书查询</div>
        </div>
      </div>
    </div>
    <div class="container-box">
      <div class="query-box">
        <div class="q-title">
          证书查询
        </div>
        <el-form class="query-form" ref="queryForm" label-position="left" label-width="80px" :model="queryFormData" :rules="rules">
          <el-form-item label="证书类型" prop="certType">
            <el-select v-model="queryFormData.certType">
              <el-option
                v-for="item in typeList"
                :key="item.certTypeId"
                :label="item.certTypeName"
                :value="item.certTypeId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名" prop="fullName">
            <el-input v-model="queryFormData.fullName" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="idCard">
            <el-input v-model="queryFormData.idCard" placeholder="请输入身份证号"></el-input>
          </el-form-item>
          <el-form-item label="证书编号">
            <el-input v-model="queryFormData.certNumber" placeholder="请输入证书编号"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="authCode">
            <div class="code-box">
              <el-input v-model="queryFormData.authCode" placeholder="请输入验证码"></el-input>
              <img @click="refreshCaptcha" class="auth-code" v-if="captcha&&captcha.authCode"  :src="'data:image/png;base64,'+captcha.authCode" alt="">
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm()" v-loading.fullscreen.lock="fullscreenLoading">查询</el-button>
            <el-button @click="resetForm()">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="isSearch">
        <div class="result-box" v-if="userCertList&&userCertList.length>0">
          <div class="r-title">
            查询结果
          </div>
          <el-descriptions title="基本信息" :column="2" border>
            <el-descriptions-item>
              <template slot="label">姓名</template>{{ userCertList[0].fullName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">性别</template>{{ userCertList[0].sex }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">身份证号</template>{{ userCertList[0].idCard }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">出生日期</template>{{ userCertList[0].birthDate?userCertList[0].birthDate:'' }}
            </el-descriptions-item>
          </el-descriptions>
          <div v-if="queryFormData.certType===1">
            <div class="r-section" v-for="item in userCertList" :key="item.certNumber">
              <el-descriptions title="证书信息" :column="2" border>
                <el-descriptions-item>
                  <template slot="label">证书类型</template>国家职业资格证书
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">职业资格</template>{{ item.ruleText }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">技能等级</template>{{ item.levelText }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">理论成绩</template>{{ item.theoryFraction }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">实操成绩</template>{{ item.operateFraction }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">证书编号</template>{{ item.certNumber }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">发证机构</template>{{ item.officeText }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">发证日期</template>{{ item.sendDate }}
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
          <div v-if="queryFormData.certType===2">
            <div class="r-section" v-for="item in userCertList" :key="item.certNumber">
              <el-descriptions title="证书信息" :column="2" border>
                <el-descriptions-item>
                  <template slot="label">证书类型</template>供销合作社行业专业技术证书
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">文化程度</template>{{ item.educationText }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">专业</template>{{ item.ruleText }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">等级</template>{{ item.levelText }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">专业资格审核</template>合格
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">理论考核成绩</template>{{ item.theoryFraction }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">操作考核成绩</template>{{ item.operateFraction }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">证书编号</template>{{ item.certNumber }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">鉴定中心</template>{{ item.officeText }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">发证日期</template>{{ item.sendDate }}
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
          <div v-if="queryFormData.certType===3">
            <div class="r-section" v-for="item in userCertList" :key="item.certNumber">
              <el-descriptions title="证书信息" :column="2" border>
                <el-descriptions-item>
                  <template slot="label">证书类型</template>职业技能等级证书
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">职业名称</template>{{ item.ruleText }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">工种/职业方向</template>{{ item.directionText }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">职业技能等级</template>{{ item.levelText }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">证书编号</template>{{ item.certNumber }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">鉴定中心</template>{{ item.officeText }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">发证日期</template>{{ item.sendDate }}
                </el-descriptions-item>
              </el-descriptions>
            </div>
            
          </div>
          <div v-if="queryFormData.certType===4">
            <div class="r-section" v-for="item in userCertList" :key="item.certNumber">
              <el-descriptions title="证书信息" :column="2" border>
                <el-descriptions-item>
                  <template slot="label">证书类型</template>职业技能等级认定考评员证书
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">职业（工种）</template>{{ item.ruleText }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">等级</template>{{ item.levelText }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">有效日期</template>{{ item.termDate }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">证书编号</template>{{ item.certNumber }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">发证日期</template>{{ item.sendDate }}
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
          <div v-if="queryFormData.certType===5">
            <div class="r-section" v-for="item in userCertList" :key="item.certNumber">
              <el-descriptions title="证书信息" :column="2" border>
                <el-descriptions-item>
                  <template slot="label">证书类型</template>职业技能竞赛裁判员证书
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">职业（工种）</template>{{ item.ruleText }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">有效日期</template>{{ item.termDate }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">证书编号</template>{{ item.certNumber }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">发证日期</template>{{ item.sendDate }}
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
          <div v-if="queryFormData.certType===6">
            <div class="r-section" v-for="item in userCertList" :key="item.certNumber">
              <el-descriptions title="证书信息" :column="2" border>
                <el-descriptions-item>
                  <template slot="label">证书类型</template>职业技能等级认定质量督导员证书
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">发证机构</template>{{ item.officeText }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">有效日期</template>{{ item.termDate }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">证书编号</template>{{ item.certNumber }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">发证日期</template>{{ item.sendDate }}
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
          <div v-if="queryFormData.certType===7">
            <div class="r-section" v-for="item in userCertList" :key="item.certNumber">
              <el-descriptions title="证书信息" :column="2" border>
                <el-descriptions-item>
                  <template slot="label">证书类型</template>职业技能培训师资证书
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">文化程度</template>{{ item.educationText }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">专业</template>{{ item.ruleText }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">证书编号</template>{{ item.certNumber }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">专业资格审核</template>合格
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">理论考核成绩</template>{{ item.theoryFraction }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">综合评定成绩</template>{{ item.evaluateFraction }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">发证日期</template>{{ item.sendDate }}
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
        </div>
        <div class="result-box" v-else>
          <div class="r-title">
            查询结果
          </div>
          <div class="nodata">
            暂无信息
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { queryUserCertList } from '../../network/certificate'
import { getetCaptcha } from '../../network/home'
import { saveLogFlow } from '../../network/log'

export default {
  name: "Certificate",
  data () {
    return {
      fullscreenLoading: false,
      isSearch: false,
      queryFormData: {
        certType: 1,
        fullName: '',
        idCard: '',
        certNumber: '',
        authCode: '',
        uuid: ''
      },
      captcha: {},
      typeList: [{
        certTypeId: 1,
        certTypeName: "国家职业资格证书"
      },{
        certTypeId: 2,
        certTypeName: "供销合作社行业专业技术证书"
      },{
        certTypeId: 3,
        certTypeName: "职业技能等级证书"
      },{
        certTypeId: 4,
        certTypeName: "职业技能等级认定考评员证书"
      },{
        certTypeId: 5,
        certTypeName: "职业技能竞赛裁判员证书"
      },{
        certTypeId: 6,
        certTypeName: "职业技能等级认定质量督导员证书"
      },{
        certTypeId: 7,
        certTypeName: "职业技能培训师资证书"
      }],
      rules: {
        certType: [
          { required: true, message: '请选择证书类型', trigger: 'change' },
        ],
        fullName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        idCard: [
          { required: true, message: '请输入身份证号', trigger: 'blur' }
        ],
        authCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      userCertList: []
    };
  },
  props: {
    
  },
  components: {
    
  },
  mounted() {
    this.getetCaptcha()
    this.saveLogFlow()

  },
  methods: {
    submitForm() {
      this.$refs.queryForm.validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true;
          this.queryUserCertList(this.queryFormData)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.queryForm.resetFields();
    },
    refreshCaptcha() {
      this.getetCaptcha()
    },
    // 网络请求
    getetCaptcha() {
      getetCaptcha().then(res=>{
        if(res.status===200&&res.data) {
          this.captcha = res.data
          this.queryFormData.uuid = this.captcha.uuid
        }
      })
    },
    queryUserCertList(queryFormData) {
      queryUserCertList(queryFormData).then(res=>{
        if(res.status===200&&res.data) {
          this.fullscreenLoading = false;
          this.isSearch = true
          this.userCertList = res.data
          console.log(this.userCertList)
        }else{
          this.fullscreenLoading = false;
          this.isSearch = true
          this.$message({
            message: res.msg,
            type: 'warning',
            offset: 350
          });
        }
      })
    },
    saveLogFlow() {
      saveLogFlow().then(res=>{
        if(res.status===200&&res.data) {
          console.log(res.data)
        }
      })
    }
  }
}
</script>

<style scoped>
  .details-content>>>img {
    max-width: 100%;
    margin: 10px 0;
  }
  .details-content>>>a {
    color: #0B5EA4 !important;
    /* text-decoration: underline !important; */
    cursor: pointer !important;
  }
  .details-content>>>a span {
    color: #0B5EA4 !important;
  }
  
  .details-content-files>>>a {
    color: #0B5EA4 !important;
    /* text-decoration: underline !important; */
    cursor: pointer !important;
  }
</style>
<style lang='less' scoped>
.container {
  width: 100%;
  .breadcrumbs-box {
    width: 100%;
    height: 40px;
    background-color: #eee;
    .breadcrumbs {
      width: 1200px;
      height: 40px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .home-icon {
        color: var(--theme-text-color);
        font-size: 14px;
      }
      .right {
        color: #535353;
        font-size: 14px;
      }
      .item {
        margin-left: 10px;
        font-size: 14px;
        color: #535353;
        cursor: pointer;
      }
    }
  }
  .container-box {
    width: 1200px;
    margin: 20px auto 35px;
    .query-box {
      width: 100%;
      padding: 0 100px;
      margin-top: 50px;
      .q-title {
        font-size: 24px;
        color: #313131;
        text-align: center;
        position: relative;
        &::after {
          content: "";
          display: block;
          width: 320px;
          height: 2px;
          background-color: #eee;
          border-bottom: 1px solid #d2d2d2;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        &::before {
          content: "";
          display: block;
          width: 320px;
          height: 2px;
          background-color: #eee;
          border-bottom: 1px solid #d2d2d2;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .query-form {
        width: 370px;
        margin: 40px auto 0;
        .code-box {
          display: flex;
          justify-content: space-between;
          .auth-code {
            height: 40px;
            cursor: pointer;
          }
        }
        
      }
    }
    .result-box {
      width: 100%;
      padding: 0 100px;
      margin-top: 50px;
      .r-title {
        font-size: 24px;
        color: #313131;
        text-align: center;
        position: relative;
        margin-bottom: 60px;
        &::after {
          content: "";
          display: block;
          width: 420px;
          height: 2px;
          background-color: #eee;
          border-bottom: 1px solid #d2d2d2;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        &::before {
          content: "";
          display: block;
          width: 420px;
          height: 2px;
          background-color: #eee;
          border-bottom: 1px solid #d2d2d2;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .r-section {
        margin-top: 30px;
      }
      .nodata {
        text-align: center;
        font-size: 18px;
        color: #999;
      }
    }
  }
}
</style>