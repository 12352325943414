<template>
	<div class="container" v-title data-title="搜索结果">
    <div class="breadcrumbs-box">
      <div class="breadcrumbs">
        <i class="el-icon-s-home home-icon"></i>
        <div class="item">
          <router-link to="/">首页</router-link>
        </div>
        <i class="el-icon-arrow-right right-icon"></i>
        <div class="item">
          <div>搜索结果</div>
        </div>
      </div>
    </div>
    <div class="container-box">
      <div class="news-list">
        <div class="title">
          <div>搜索结果</div>
          <div class="search-box">
            <input v-model="title" type="text" class="search-input" placeholder="请输入搜索内容" @keydown.enter="reSearchList">
            <div class="search-btn" @click="reSearchList">
              <img src="../../assets/img/search-btn.png" alt="">
            </div>
          </div>
        </div>
        <ul class="news-box" v-if="newsList&&newsList.length>0">
          <li class="news-item" v-for="item in newsList" :key="item.newsNumber">
            <router-link target="_blank" :to="'/newsdetails?newsNumber='+item.newsNumber">
              <span class="item-title">{{ item.title }}</span>
              <span class="item-time">{{ item.createTime.split(' ')[0] }}</span>
            </router-link>
          </li>
          
        </ul>
        <div class="news-box" v-else>
          <div class="no-data">暂无数据</div>
        </div>
        <div class="page-box">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="size"
            layout="prev, pager, next, total, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pageNewsList } from '../../network/news'
import { saveLogFlow } from '../../network/log'


export default {
  name: "NewsList",
  data () {
    return {
      newsList: [],
      menuId: 0,
      page: 1,
      size: 10,
      total: 0,
      title: ''
    };
  },
  props: {
    
  },
  components: {
    
  },
  mounted() {
    const params = this.$route.query;
    if(params && params.searchText) {
      this.title = params.searchText
      this.pageNewsList(this.menuId,this.page,this.size,this.title)
    }
    
    this.saveLogFlow()
  },
  methods: {
    reSearchList() {
      this.pageNewsList(this.menuId,this.page,this.size,this.title)
    },
    handleCurrentChange(val) {
      this.page = val
      this.pageNewsList(this.menuId,this.page,this.size,this.title)
    },
    // 网络请求
    pageNewsList(menuId,page,size,title) {
      pageNewsList(menuId,page,size,title).then(res=>{
        if(res.status===200&&res.data) {
          this.newsList = res.data
          this.total = res.total
        }
      })
    },
    saveLogFlow() {
      saveLogFlow().then(res=>{
        if(res.status===200&&res.data) {
          console.log(res.data)
        }
      })
    }
  }
}
</script>

<style lang='less' scoped>
.container {
  width: 100%;
  .breadcrumbs-box {
    width: 100%;
    height: 40px;
    background-color: #eee;
    .breadcrumbs {
      width: 1200px;
      height: 40px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .home-icon {
        color: var(--theme-text-color);
        font-size: 14px;
      }
      .right {
        color: #535353;
        font-size: 14px;
      }
      .item {
        margin-left: 10px;
        font-size: 14px;
        color: #535353;
        cursor: pointer;
      }
    }
  }
  .container-box {
    width: 1200px;
    margin: 20px auto 35px;
    display: flex;
    justify-content: center;
    .news-list {
      width: 870px;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 18px;
        color: #313131;
        font-weight: bold;
        border-bottom: 1px solid #7d7d7d;
        padding: 10px 0;
        position: relative;
        &::after {
          content: "";
          display: inline-block;
          width: 74px;
          height: 6px;
          background-color: var(--theme-color);
          position: absolute;
          left: 0;
          bottom: -3px;
        }

        .search-box {
          width: 341px;
          height: 42px;
          display: flex;
          justify-content: start;
          align-items: center;
          border: 1px solid #dcdcdc;
          border-radius: 6px;
          .search-input {
            width: 300px;
            height: 40px;
            border: none;
            outline: none;
            padding-left: 10px;
            background-color: #fff;
            font-size: 16px;
            border-radius: 6px 0 0 6px;
          }
          .search-btn {
            width: 41px;
            height: 40px;
            background-color: #fff;
            border-radius: 0 6px 6px 0;
            border-left: 1px solid #d2d2d2;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .news-box {
        padding-top: 12px;
        .no-data {
          color: #999;
          font-size: 14px;
          text-align: center;
          padding: 10px 0;
        }
        .news-item {
          padding: 0 20px;
          height: 40px;
          line-height: 40px;
          border-bottom: 1px dashed #d2d2d2;
          list-style-type: none;
          padding-left: 20px;
          color: #1b1b1b;
          font-size: 16px;
          cursor: pointer;
          position: relative;
          display: flex;

          &::before {
            content: "";
            display: inline-block;
            width: 5px;
            height: 5px;
            background-color: #1b1b1b;
            rotate: 45deg;
            margin-right: 10px;
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
          }
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
            display: flex;
            .item-title {
              flex: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .time {
              width: 80px;
              text-align: right;
            }
          }
        }
      }
      .page-box {
        padding-top: 60px;
        text-align: center;
      }
    }
    .other-news-list {
      width: 310px;
      .title {
        border-top: 10px solid var(--theme-color);
        background-color: #eee;
        height: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 34px;
        font-size: 20px;
        font-weight: bold;
        color: var(--theme-text-color);
        .right-icon {
          color: var(--theme-text-color);
        }
      }
      .news-box {
        border: 1px solid #e5e5e5;
        padding: 10px 20px;
        .news-item {
          padding: 0 20px;
          height: 35px;
          line-height: 35px;
          list-style-type: none;
          padding-left: 20px;
          color: #1b1b1b;
          font-size: 16px;
          cursor: pointer;
          position: relative;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &::before {
            content: "";
            display: inline-block;
            width: 5px;
            height: 5px;
            background-color: var(--theme-color);
            rotate: 45deg;
            margin-right: 10px;
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
</style>
