import { render, staticRenderFns } from "./NewsPage.vue?vue&type=template&id=66703ee3&scoped=true&"
import script from "./NewsPage.vue?vue&type=script&lang=js&"
export * from "./NewsPage.vue?vue&type=script&lang=js&"
import style0 from "./NewsPage.vue?vue&type=style&index=0&id=66703ee3&scoped=true&lang=css&"
import style1 from "./NewsPage.vue?vue&type=style&index=1&id=66703ee3&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66703ee3",
  null
  
)

export default component.exports