<template>
	<div class="container" v-title data-title="专家人才">
    <div class="breadcrumbs-box">
      <div class="breadcrumbs">
        <i class="el-icon-s-home home-icon"></i>
        <div class="item">
          <router-link tag="div" to="/">首页</router-link>
        </div>
        <i class="el-icon-arrow-right right-icon"></i>
        <div class="item">
          <div>专家人才</div>
        </div>
      </div>
    </div>
    <div class="container-box">
      <div class="other-news-list">
        <div class="title">
          专家人才
        </div>
        <!-- <ul class="news-box">
          <li :class="['news-item',menuActive===item.menuId?'selected':'']" v-for="item in menuList" :key="item.menuId" @click="selectMenu(item)">
            <div>{{ item.menuName }}</div>
            <div>>></div>
          </li>
        </ul> -->
      </div>

      <div class="news-list">
        <div class="title">
          专家人才
        </div>
        <ul class="news-box" v-if="expertList&&expertList.length>0">
          <li class="news-item" v-for="item in expertList" :key="item.expertNumber">
            <router-link target="_blank" :to="'/expertdetails?expertNumber='+item.expertNumber">
              <span class="item-title">{{ item.expertName }}</span>
              <!-- <span class="item-time">{{ item.createTime.split(' ')[0] }}</span> -->
            </router-link>
          </li>
        </ul>
        <div class="news-box" v-else>
          <div class="no-data">暂无数据</div>
        </div>
        <div class="page-box">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="size"
            layout="prev, pager, next, total, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pageExpertList } from '../../network/expert'
import { saveLogFlow } from '../../network/log'

export default {
  name: "ExpertList",
  data () {
    return {
      expertList: [],
      isHome: -1,
      page: 1,
      size: 10,
      total: 0,
      expertName: '',
      typeId: 0,
      menuList: [],
      menuActive: 0,
    };
  },
  props: {
    
  },
  components: {
    
  },
  mounted() {
    this.pageExpertList(this.isHome,this.page,this.size,this.typeId,this.expertName)

    this.saveLogFlow()
  },
  methods: {
    selectMenu(val) {
      
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    // 网络请求
    pageExpertList(isHome,page,size,typeId,expertName) {
      pageExpertList(isHome,page,size,typeId,expertName).then(res=>{
        if(res.status===200&&res.data) {
          this.expertList = res.data
          this.total = res.total
          console.log('专家列表',this.expertList)
        }
      })
    },
    saveLogFlow() {
      saveLogFlow().then(res=>{
        if(res.status===200&&res.data) {
          console.log(res.data)
        }
      })
    }
    // getNewsAllInfoByMenuId(menuId) {
    //   getNewsAllInfoByMenuId(menuId).then(res=>{
    //     if(res.status===200&&res.data) {
    //       this.newsPage = res.data
    //       console.log('新闻单页',this.newsPage)
    //     }
    //   })
    // },
    // getWebMenuList(menuId) {
    //   getWebMenuList(menuId).then(res=>{
    //     if(res.status===200&&res.data) {
    //       this.menuList = res.data
    //       this.menuActive = this.menuId
    //       if(!this.pid) {
    //         this.menuActive = this.menuList[0].menuId
    //         this.curLayoutMode = this.menuList[0].layoutMode
    //       }else{
            
    //         let curMenuList = this.menuList.filter(item=>{
    //           return item.menuId === this.menuId
    //         })

    //         if(curMenuList.length>0) {
    //           this.curMenu = curMenuList[0]
    //         }
    //         this.curLayoutMode = this.curMenu.layoutMode
    //       }
    //       if(this.curLayoutMode===2){
    //         this.getNewsAllInfoByMenuId(this.curMenu.menuId)
    //       }else if(this.curLayoutMode===1){
    //         this.pageNewsList(this.menuId,this.page,this.size,this.title)
    //       }

    //       console.log('当前菜单',this.curMenu)
    //       console.log('列表形式',this.curLayoutMode)
    //       console.log('菜单列表',this.menuList)
    //     }
    //   })
    // },
  }
}
</script>

<style lang='less' scoped>
.container {
  width: 100%;
  .breadcrumbs-box {
    width: 100%;
    height: 40px;
    background-color: #eee;
    .breadcrumbs {
      width: 1200px;
      height: 40px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .home-icon {
        color: var(--theme-text-color);
        font-size: 14px;
      }
      .right {
        color: #535353;
        font-size: 14px;
      }
      .item {
        margin-left: 10px;
        font-size: 14px;
        color: #535353;
        cursor: pointer;
      }
    }
  }
  .container-box {
    width: 1200px;
    margin: 20px auto 35px;
    display: flex;
    justify-content: space-between;
    .news-list {
      width: 870px;
      .title {
        width: 100%;
        font-size: 18px;
        color: #313131;
        font-weight: bold;
        border-bottom: 1px solid #7d7d7d;
        padding: 10px 0;
        position: relative;
        &::after {
          content: "";
          display: inline-block;
          width: 74px;
          height: 6px;
          background-color: var(--theme-color);
          position: absolute;
          left: 0;
          bottom: -3px;
        }
      }
      .news-box {
        padding-top: 12px;
        .no-data {
          color: #999;
          font-size: 14px;
          text-align: center;
          padding: 10px 0;
        }
        .news-item {
          padding: 0 20px;
          height: 40px;
          line-height: 40px;
          border-bottom: 1px dashed #d2d2d2;
          list-style-type: none;
          padding-left: 20px;
          color: #1b1b1b;
          font-size: 16px;
          cursor: pointer;
          position: relative;
          display: flex;

          &::before {
            content: "";
            display: inline-block;
            width: 5px;
            height: 5px;
            background-color: #1b1b1b;
            rotate: 45deg;
            margin-right: 10px;
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
          }
          .item-title {
            flex: 1;
          }
          .time {
            width: 71px;
          }
        }
      }
      .page-box {
        padding-top: 60px;
        text-align: center;
      }
    }
    .other-news-list {
      width: 310px;
      .title {
        border-top: 10px solid var(--theme-color);
        background-color: #eee;
        height: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 34px;
        font-size: 20px;
        font-weight: bold;
        color: var(--theme-text-color);
        .right-icon {
          color: var(--theme-text-color);
        }
      }
      .news-box {
        border-bottom: 80px solid #e5e5e5;
        .news-item {
          padding: 0 20px;
          height: 49px;
          line-height: 49px;
          list-style-type: none;
          padding-left: 20px;
          color: #1b1b1b;
          font-size: 16px;
          cursor: pointer;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #e5e5e5;
          &.selected {
            background-color: var(--theme-color);
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
