import { render, staticRenderFns } from "./NewsList.vue?vue&type=template&id=62202e3d&scoped=true&"
import script from "./NewsList.vue?vue&type=script&lang=js&"
export * from "./NewsList.vue?vue&type=script&lang=js&"
import style0 from "./NewsList.vue?vue&type=style&index=0&id=62202e3d&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62202e3d",
  null
  
)

export default component.exports