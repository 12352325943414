<template>
	<div class="container" v-title data-title="证书查询">
    <div class="breadcrumbs-box">
      <div class="breadcrumbs">
        <i class="el-icon-s-home home-icon"></i>
        <div class="item">
          <div>
            <router-link to="/">首页</router-link>
          </div>
        </div>
        <i class="el-icon-arrow-right right-icon"></i>
        <div class="item">
          <div>证书查询</div>
        </div>
      </div>
    </div>
    <div class="container-box">
      <div class="query-box">
        <div class="q-title">
          证书查询
        </div>
        <el-form class="query-form" ref="queryForm" label-position="left" label-width="80px" :model="queryFormData" :rules="rules">
          <el-form-item label="证书编号" prop="certNumber">
            <el-input v-model="queryFormData.certNumber" placeholder="请输入证书编号"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="authCode">
            <div class="code-box">
              <el-input v-model="queryFormData.authCode" placeholder="请输入验证码"></el-input>
              <img @click="refreshCaptcha" class="auth-code" v-if="captcha&&captcha.authCode"  :src="'data:image/png;base64,'+captcha.authCode" alt="">
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm()" v-loading.fullscreen.lock="fullscreenLoading">查询</el-button>
            <el-button @click="resetForm()">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="isSearch">
        <div class="result-box" v-if="certList&&certList.length>0">
          <div class="r-title">
            查询结果
          </div>
          <ul class="news-box">
            <li class="news-item" v-for="item in certList" :key="item.id" @click="openCert(item)">
              <span class="item-span">{{ item.certNumber }}</span>
              <span class="item-span">{{ item.typeName }}</span>
              <span class="item-span">{{ item.company }}</span>
              <span class="item-span">{{ item.sendDate?item.sendDate:'-' }}</span>
            </li>
          </ul>
        </div>
        <div class="result-box" v-else>
          <div class="r-title">
            查询结果
          </div>
          <div class="nodata">
            暂无信息
          </div>
        </div>
      </div>

      <el-dialog title="证书详情" :visible.sync="dialogFormVisible" width="40%">
        <el-image style="width: 100%;" :src="curCertUrl" fit="fill"></el-image>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
        </div>
      </el-dialog>
      
    </div>
  </div>
</template>

<script>
import { queryCert2List } from '../../network/coiccert'
import { getetCaptcha } from '../../network/home'
import { saveLogFlow } from '../../network/log'

export default {
  name: "Coiccert",
  data () {
    return {
      fullscreenLoading: false,
      isSearch: false,
      queryFormData: {
        certNumber: '',
        authCode: '',
        uuid: ''
      },
      captcha: {},
      rules: {
        certNumber: [
          { required: true, message: '请输入证书编号', trigger: 'blur' }
        ],
        authCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      certList: [],
      curCertUrl: '',
      dialogFormVisible: false
    };
  },
  props: {
    
  },
  components: {
    
  },
  mounted() {
    this.getetCaptcha()
    this.saveLogFlow()

  },
  methods: {
    openCert(item) {
      this.curCertUrl = item.certUrl
      this.dialogFormVisible = true
    },
    submitForm() {
      this.$refs.queryForm.validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true;
          this.queryCert2List(this.queryFormData)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.queryForm.resetFields();
    },
    refreshCaptcha() {
      this.getetCaptcha()
    },
    // 网络请求
    getetCaptcha() {
      getetCaptcha().then(res=>{
        if(res.status===200&&res.data) {
          this.captcha = res.data
          this.queryFormData.uuid = this.captcha.uuid
        }
      })
    },
    queryCert2List(queryFormData) {
      queryCert2List(queryFormData).then(res=>{
        if(res.status===200&&res.data) {
          this.fullscreenLoading = false;
          this.isSearch = true
          this.certList = res.data
          console.log(this.certList)
        }
      })
    },
    saveLogFlow() {
      saveLogFlow().then(res=>{
        if(res.status===200&&res.data) {
          console.log(res.data)
        }
      })
    }
  }
}
</script>

<style scoped>
  .details-content>>>img {
    max-width: 100%;
    margin: 10px 0;
  }
  .details-content>>>a {
    color: #0B5EA4 !important;
    /* text-decoration: underline !important; */
    cursor: pointer !important;
  }
  .details-content>>>a span {
    color: #0B5EA4 !important;
  }
  
  .details-content-files>>>a {
    color: #0B5EA4 !important;
    /* text-decoration: underline !important; */
    cursor: pointer !important;
  }
</style>
<style lang='less' scoped>
.container {
  width: 100%;
  .breadcrumbs-box {
    width: 100%;
    height: 40px;
    background-color: #eee;
    .breadcrumbs {
      width: 1200px;
      height: 40px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .home-icon {
        color: var(--theme-text-color);
        font-size: 14px;
      }
      .right {
        color: #535353;
        font-size: 14px;
      }
      .item {
        margin-left: 10px;
        font-size: 14px;
        color: #535353;
        cursor: pointer;
      }
    }
  }
  .container-box {
    width: 1200px;
    margin: 20px auto 35px;
    .query-box {
      width: 100%;
      padding: 0 100px;
      margin-top: 50px;
      .q-title {
        font-size: 24px;
        color: #313131;
        text-align: center;
        position: relative;
        &::after {
          content: "";
          display: block;
          width: 320px;
          height: 2px;
          background-color: #eee;
          border-bottom: 1px solid #d2d2d2;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        &::before {
          content: "";
          display: block;
          width: 320px;
          height: 2px;
          background-color: #eee;
          border-bottom: 1px solid #d2d2d2;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .query-form {
        width: 370px;
        margin: 40px auto 0;
        .code-box {
          display: flex;
          justify-content: space-between;
          .auth-code {
            height: 40px;
            cursor: pointer;
          }
        }
        
      }
    }
    .result-box {
      width: 100%;
      padding: 0 100px;
      margin-top: 50px;
      .r-title {
        font-size: 24px;
        color: #313131;
        text-align: center;
        position: relative;
        margin-bottom: 60px;
        &::after {
          content: "";
          display: block;
          width: 420px;
          height: 2px;
          background-color: #eee;
          border-bottom: 1px solid #d2d2d2;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        &::before {
          content: "";
          display: block;
          width: 420px;
          height: 2px;
          background-color: #eee;
          border-bottom: 1px solid #d2d2d2;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .news-box {
        // padding-top: 12px;
        .news-item {
          padding: 5px 20px;
          height: 40px;
          line-height: 40px;
          border-bottom: 1px dashed #d2d2d2;
          list-style-type: none;
          padding-left: 20px;
          color: #1b1b1b;
          font-size: 16px;
          cursor: pointer;
          position: relative;
          display: flex;

          &::before {
            content: "";
            display: inline-block;
            width: 5px;
            height: 5px;
            background-color: #1b1b1b;
            rotate: 45deg;
            margin-right: 10px;
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
          }
          display: inline-block;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          .item-span {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:last-child {
              text-align: right;
            }
          }
        }
      }
      .nodata {
        text-align: center;
        font-size: 18px;
        color: #999;
      }
    }
  }
}
</style>

