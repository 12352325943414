import {request} from './request'
import baseUrlObj from "./baseURL"
const systemNumber = baseUrlObj.systemNumber

// 记录访问流量 /ewi/guest/Log/SaveLogFlow
export function saveLogFlow(){
  return request({
    url: '/ewi/guest/Log/SaveLogFlow',
    method: 'post',
    data: {
      systemNumber: systemNumber
    }
  })
}
